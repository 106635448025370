/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #55aa55;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #88dd88;
}